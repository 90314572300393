import React, { useState, useMemo } from 'react'
import { HeaderContainer, Inner, LogoLink, LogoMob, LogoDesk, MenuToggle, DeskMenu, MenuLink, MenuIcon, MenuLine, SocialLink, SocialIcon, MobileMenu, MobileLink, MobileList, MobileSocial, MobileSocialLink } from './headerStyles'
import { DonateButton } from '../globalStyles';

const Header = ({links}) => {
    const [active, setActive] = useState(false);

    return useMemo(() => (        
        <HeaderContainer role={`navigation`} aria-label={`main-navigation`}>
            <Inner>
                <LogoLink to="/" title={`Wangaratta Jazz & Blues`} target={`_self`} onClick={() => setActive(false)}>
                    <LogoMob 
                        name={'logoSmall'}
                    />
                    <LogoDesk
                        name={'logoLarge'}
                    />
                </LogoLink>
                {links?.length ? (
                    <MenuToggle aria-label={`Menu`} onClick={() => setActive(!active)}>
                        <MenuIcon
                            active={active}
                            name={active ? `cross` : `menu`}
                        />
                    </MenuToggle>
                ) : null}
                {links?.length ? (
                    <DeskMenu>
                        {links?.map((link, i) => {
                            return link.title.toLowerCase() === 'donate' ? (
                                <DonateButton
                                    key={link.title}
                                    href={link.url}
                                    target={link?.external ? '_blank' : '_self'}
                                >
                                    {link.title}
                                </DonateButton>
                            ) : (
                                <MenuLink 
                                    key={link.title} 
                                    to={link.url} 
                                    title={link.title} 
                                    target={link?.external ? '_blank' : '_self'}
                                    index={i}
                                    style={{ transition: `all ${0.5 + (0.1 * (i + 1))}s ease`}}
                                    onClick={() => setActive(false)}
                                >
                                    {link.title}
                                </MenuLink>
                            )
                        })}
                        <MenuLine />
                        <SocialLink href={`https://www.facebook.com/WangarattaJazzFestival/`} title={`Facebook`} target={`_blank`}>
                            <SocialIcon name={`facebookCircle`} />
                        </SocialLink>
                        <SocialLink href={`https://www.instagram.com/wangarattajazzandblues/`} title={`Instagram`} target={`_blank`}>
                            <SocialIcon name={`instagramCircle`} />
                        </SocialLink>
                        <SocialLink href={`https://www.youtube.com/@WangarattaFestivalofJazzandBlu`} title={`Youtube`} target={`_blank`}>
                            <SocialIcon name={`youtubeCircle`} />
                        </SocialLink>
                    </DeskMenu>
                ) : null}
                {links?.length ? (
                    <MobileMenu active={active}>
                        <MobileList>
                            {links?.map((link, i) => (
                                <MobileLink 
                                    key={link.title} 
                                    to={link.url} 
                                    title={link.title} 
                                    target={link?.external ? '_blank' : '_self'}
                                    index={i}
                                    onClick={() => setActive(false)}
                                >
                                    {link.title}
                                </MobileLink>
                            ))}
                        </MobileList>
                        <MobileSocial>
                            <MobileSocialLink href={`https://www.facebook.com/WangarattaJazzFestival/`} title={`Facebook`} target={`_blank`}>
                                <SocialIcon name={`facebookCircle`} />
                            </MobileSocialLink>
                            <MobileSocialLink href={`https://www.instagram.com/wangarattajazzandblues/`} title={`Instagram`} target={`_blank`}>
                                <SocialIcon name={`instagramCircle`} />
                            </MobileSocialLink>
                            <MobileSocialLink href={`https://www.youtube.com/@WangarattaFestivalofJazzandBlu`} title={`Youtube`} target={`_blank`}>
                                <SocialIcon name={`youtubeCircle`} />
                            </MobileSocialLink>
                        </MobileSocial>
                    </MobileMenu>
                ) : null}
            </Inner>
        </HeaderContainer>
    ), [active, links]);
}

export default Header
