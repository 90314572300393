import React from 'react';
import ArrowUp from '../../static/icons/arrow-up.svg';
import ArrowDown from '../../static/icons/arrow-down.svg';
import ChevronLeft from '../../static/icons/chevron-left.svg';
import Cross from '../../static/icons/cross.svg';
import Download from '../../static/icons/download.svg';
import FacebookCircle from '../../static/icons/facebook-circle.svg';
import GradientAmber from '../../static/icons/gradient-burnt-amber.svg';
import GradientMidnight from '../../static/icons/gradient-midnight.svg';
import InstagramCircle from '../../static/icons/instagram-circle.svg';
import LogoLarge from '../../static/icons/logo-large.svg';
import LogoSmall from '../../static/icons/logo-small.svg';
import Menu from '../../static/icons/menu.svg';
import Play from '../../static/icons/play.svg';
import YoutubeCircle from '../../static/icons/youtube-circle.svg';

const icons = {
    arrowUp: ArrowUp,
    arrowDown: ArrowDown,
    cross: Cross,
    chevronLeft: ChevronLeft,
    download: Download,
    facebookCircle: FacebookCircle,
    gradientAmber: GradientAmber,
    gradientMidnight: GradientMidnight,
    instagramCircle: InstagramCircle,
    logoLarge: LogoLarge,
    logoSmall: LogoSmall,
    menu: Menu,
    play: Play,
    youtubeCircle: YoutubeCircle
}

export const Icon = ({name, className}) => {
    const IconElement = icons[name];
    return IconElement ? (
        <IconElement className={className} />
    ) : null;
}

export default Icon;