import React from 'react'
import { FooterOuter, FooterContainer, FooterRow, Logo, Image, SocialLink, SocialIcon, LogoLink, SocialGroup, FooterCopyright, Copyright, MenuLinks, MenuItem, LogoGroup } from './footerStyles'
import { formatUrl } from "../../lib/helpers"

const Footer = ({links, sponsors}) => {
    return (
        <FooterOuter>
            <FooterContainer>
                <FooterRow>
                    <MenuLinks>
                        {links?.map((link, i) => (
                            <MenuItem 
                                key={link.title} 
                                to={link.url} 
                                title={link.title} 
                                target={link?.external ? '_blank' : '_self'}
                                index={i}
                            >
                                {link.title}
                            </MenuItem>
                        ))}
                    </MenuLinks>
                    <SocialGroup>
                        <SocialLink href={`https://www.facebook.com/WangarattaJazzFestival/`} title={`Facebook`} target={`_blank`}>
                            <SocialIcon name={`facebookCircle`} />
                        </SocialLink>
                        <SocialLink href={`https://www.instagram.com/wangarattajazzandblues/`} title={`Instagram`} target={`_blank`}>
                            <SocialIcon name={`instagramCircle`} />
                        </SocialLink>
                        <SocialLink href={`https://www.youtube.com/@WangarattaFestivalofJazzandBlu`} title={`Youtube`} target={`_blank`}>
                            <SocialIcon name={`youtubeCircle`} />
                        </SocialLink>
                    </SocialGroup>
                </FooterRow>
                <FooterRow>
                    <LogoGroup>
                        {sponsors?.map((s) => {
                            const link = s.link ? formatUrl(s.link, s.title) : null;
                            return link && s?.image?.asset?.url ? (
                                <LogoLink key={link?.url} as={`a`} href={link?.url} target={`_blank`} title={s?.title}>
                                    <Image imgStyle={{objectFit: `cover`}} src={s?.image?.asset?.url} alt={s?.title} />
                                </LogoLink>
                            ) : null
                        })}
                    </LogoGroup>
                </FooterRow>
                <FooterCopyright>
                    <Copyright>
                        The Festival welcomes, and acknowledges the traditional owners of the lands on which the Festival occurs. We thank Elders, past, present and emerging, who cared for, and continue to care for the lands and waters of Victoria.
                    </Copyright>
                    <Copyright>
                        &copy; Wangaratta Festival of Jazz & Blues
                    </Copyright>
                </FooterCopyright>
            </FooterContainer>
        </FooterOuter>
    )
}

export default Footer
